.container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    flex-direction: row-reverse;
    position: relative;
  }

  .daftar {
    color: red !important;
    margin-left: 5px;
  }
  
  .daftar:hover {
    cursor: pointer;
  }

  .wrapp-image {
    margin-right: 100px;
    width: 600px;
    height: 500px;
    animation: animation 2s linear;
  }
  
  .wrapp-form {
    padding: 20px 100px;
    height: 550px;
    border: 5px solid white;
    box-shadow: 0 5px 10px rgb(255, 255, 255);
    color: #000;
    width: 50%;
  }
  
  .wrapp-fields {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    margin-top: 25px;
  }
  
  .css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root {
    color: #FFF !important;
  }
  
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    color: #FFF !important;
  }

  @keyframes animation {
    0% { transform: scale(0); }
    50% { transform: scale(2); }
    100% { transform: scale(1); }
  }

  @keyframes spin2 {
    0% { transform: scale(0); }
    50% { transform: scale(2); }
    100% { transform: scale(1); }
  }

  @keyframes spin3 {
    0% { transform: scale(0); }
    50% { transform: scale(2); }
    100% { transform: scale(1); }
  }